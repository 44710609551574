.box {
    position:relative;
    overflow:hidden;}

.new-ribbon {
    background-color: $primary-color;
    overflow: hidden;
    position: absolute;
    right: -40px;
    top: 10px;
    -moz-transform: rotate(-45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);

    span {
        color: white;
        display: block;
        font-size: 12px;
        font-weight:bold;
        padding: 0.5em 4em;
        text-align: center;
        text-decoration: none;
    }
}