.site-nav{
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: center !important;
   
    h4 {
        color: $default-color;
        margin-top: 0px;
        margin-bottom: 0px;
        margin-left: 12px;
        margin-right: 12px;
    }
    
    h5 {
        color: $info-color-alert;
        margin-top: 0px;
        margin-bottom: 0px;
        margin-left: 12px;
        margin-right: 12px;
        cursor: pointer;
    }
}

.site-nav-top{
    margin-top: 20px !important;
}

.site-nav-down {
    margin-bottom: 20px !important;
}