@media screen and (max-width: 1000px) {
    .main-page-responsiveness-laser-big{
        visibility: hidden;
    }
}

@media screen and (min-width: 1000px) {
    .main-page-responsiveness-laser-small{
        visibility: hidden;
    }
}

@media screen and (max-width: 1000px) {
    .hide-on-small{
        visibility: hidden;
    }
}