@media screen and (max-width: 768px) {
    .social-container{
        top: 89% !important;
        right: -10px !important;
    }
}

.social-container {
    position: fixed;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    top: 5px;
    right: 10px;
    background-color: transparent;
    width: 80px;
    align-items: center;
    height: 240px;
    padding: 10px;
    z-index: 1000;

    img {
        margin: 3px;
        height: 50px;
        width: 50px;
        transition-duration: 0.5s;
        cursor: pointer;

        &:hover {
            transform: scale(1.3);
        }
    }   
}