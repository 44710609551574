.color-card {
    border: 0;
    border-radius: $border-radius-small;
    display: inline-block;
    position: relative;
    overflow: hidden;
    border: solid 1px $default-color;
    width: 100%;
    margin-bottom: 20px;
    box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);

}

.fixed-upper-left{
    position: absolute; 
    top:5px; 
    left: 5px; 
    z-index: 10; 
    font-size: 1.2em;
}

.fixed-bottom-right {
    position: absolute; 
    bottom: 10px; 
    right: 0px; 
    z-index: 10; 
    font-size: 0.8em;
}