.my-carousel{
    background: #303030; 
    border: 2px solid #303030;
}

.carousel-item img {
    width: auto;
    height: 72vh;
    max-height: 72vh;
    object-fit: cover;
    // object-position: 50% 50%;
}

.four-col-wrap {
    display: flex;
    flex-wrap: wrap;
}

.four-col-child {
    flex: 1 0 26%;
    text-align: left;
    max-width: 237px;
    min-width: 200px;
}

.carousel-control-prev-icon{
    width: 50px !important;
    height: 50px !important;
 }

 .carousel-control-next-icon{
    width: 50px !important;
    height: 50px !important;
 }

.full-screen-modal {
    .modal-dialog{
        position: relative;
        max-width: 100%;
        height: 100%;
        margin: 0;
    }

    .model-content {
        height: 100%;
    }

    .carousel-item {
        text-align: center;
    }

    .carousel-item img {
        width: auto;
        height: 99.5vh;
        max-height: 99.5vh;
        object-fit: cover;
        object-position: 50% 50%;
    }

    .my-carousel{
        background: #303030; 
        border: 2px solid #303030;
    }

    .carousel-control-prev-icon{
        width: 50px !important;
        height: 50px !important;
     }

     .carousel-control-next-icon{
        width: 50px !important;
        height: 50px !important;
     }
}